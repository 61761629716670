<template>
    <div class="w-full flex flex-col items-start justify-start">
        <div class="flex flex-row items-center justify-between w-full py-3">
            <div class="flex flex-row items-center justify-start">
                <label class="mr-4">Év:</label>
                <select v-model="year">
                    <option v-for="y in years" v-html="y" :value="y"></option>
                </select>
            </div>
            <div class="flex flex-row items-center justify-start relative">
                <label class="mr-4">Szűrés</label>
                <input type="text" v-model="search">
                <span class="rounded-full cursor-pointer absolute h-6 w-6 bg-gray-200 mr-1 z-10 flex items-center justify-center text-sm right-0"
                      v-on:click="search = ''"
                      v-if="search != ''">&times;</span>
            </div>
        </div>
        <div class="w-full">
            <div v-if="loading" class="w-full flex items-center justify-center p-8"><img src="/img/ajax-loader.gif" class="h-4"></div>
            <template v-else>
                <table class="w-full mentesek-table">
                    <thead>
                    <tr>
                        <th class="w-3/4 bg-mdblue p-3 text-white font-bold text-left cursor-pointer" v-on:click="toggleSort('filename_lc')">
                            Dokumentum neve
                            <span class="ml-3 text-xl" v-html="sortingArrows('filename_lc')"></span>
                        </th>
                        <th class="w-1/4 bg-mdblue p-3 text-white font-bold text-left cursor-pointer text-right" v-on:click="toggleSort('timestamp')">
                            Utolsó mentés
                            <span class="ml-3 text-xl" v-html="sortingArrows('timestamp')"></span>
                        </th>
                    </tr>
                    </thead>
                </table>
                <div  style="max-height: 40vh; overflow-y:auto">
                    <table class="w-full mentesek-table">
                        <tbody>
                        <tr v-for="backup in backupsSortedAndFiltered"  v-on:click="selectFile(backup.filename)">
                            <td class="w-3/4 p-1" v-html="backup.filename"></td>
                            <td class="w-1/4 p-1 text-right" v-html="backup.time_label"></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="w-full bg-mdblue text-white text-right py-2 px-4">
                    {{ backupsSortedAndFiltered.length }} találat
                </div>
            </template>
        </div>
        <div v-if="selectedFile != null" class="w-full flex justify-between items-center py-4 px-2 mt-4 bg-mdblue text-white">
            <div>Kiválasztott fájl: <span class="font-bold">{{ selectedFile }}</span></div>
            <div class="flex">
                <button v-if="selectedFileVersionsCount > 0" v-on:click="showVersions" class="mr-4 flex items-center justify-between">
                    Mentett verziók
                    <span class="rounded-full bg-white ml-3 text-mdblue text-sm h-6 w-6 flex items-center justify-center" v-html="(selectedFileVersionsCount + 1).toString()"></span>
                </button>
                <a class="btn" :href="getSelectedFileUrl(null)">Utolsó mentés letöltése</a>
            </div>
        </div>
        <popup :visible="showVersionPopup"
               :show-close-button="true"
               v-on:close="showVersionPopup = false"
               :close-button-label="'Bezár'"
        >
            <div v-if="versionsLoading"><img src="/img/ajax-loader.gif" class="h-4"></div>
            <div v-else class="w-full p-4">
                <h2 class="font-bold pb-4" v-html="selectedFile+' elérhető verziói'"></h2>
                <div class="w-full overflow-y-auto" style="max-height: 30vh">
                    <table class="w-full mentesek-verziok-table">
                        <tr v-for="v in selectedFileVersions">
                            <td class="text-left p-3" v-html="v.label"></td>
                            <td class="text-right p-3">
                                <a class="btn" :href="getSelectedFileUrl(v.timestamp)">Letöltés</a>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </popup>
    </div>
</template>

<script>
    export default {
        props: {
            operationsUrl: {type: String},
            downloadUrl: {type: String},
            years: {type: Array}
        },
        data: function () {
            return {
                year: null,
                backups: [],
                loading: true,
                search: '',
                sortBy: 'timestamp',
                sortDirection: 'desc',
                selectedFile: null,
                selectedFileVersionsCount: 0,
                showVersionPopup: false,
                selectedFileVersions: [],
                versionsLoading: false,
            }
        },
        created() {
        },
        mounted() {
            this.year = this.years[0];
        },
        methods: {
            showVersions: function() {
                this.versionsLoading = true;
                this.showVersionPopup = true;
                window.axios.post(this.operationsUrl, {action: 'getVersions', filename: this.selectedFile})
                    .then((response) => {
                        this.selectedFileVersions = response.data.versions;
                        this.versionsLoading = false;
                    })
            },
            selectFile: function(filename) {
                window.axios.post(this.operationsUrl, {action: 'countVersions', filename: filename})
                    .then((response) => {
                        this.selectedFile = filename;
                        this.selectedFileVersionsCount = response.data.versionsCount;
                    })
            },
            sortingArrows: function(sortby) {
                if (this.sortBy != sortby) {
                    return '';
                }
                if (this.sortDirection == 'desc') {
                    return '<span style="opacity:1">↓</span><span style="opacity:.4">↑</span>';
                } else {
                    return '<span style="opacity:.4">↓</span><span style="opacity:1">↑</span>';
                }
            },
            fetchBackups: function() {
                this.loading = true;
                this.selectedFile = null;
                this.selectedFileVersionsCount = 0;
                this.selectedFileVersions = [];
                window.axios.post(this.operationsUrl, {year: this.year, action: 'fetch'})
                    .then((response) => {
                        this.loading = false;
                        this.backups = response.data.backups;
                    })

            },
            toggleSort: function(sortby) {
                if (sortby == this.sortBy) {
                    this.sortDirection = this.sortDirection == 'asc' ? 'desc' : 'asc';
                } else {
                    this.sortBy = sortby;
                }
            },
            getSelectedFileUrl: function(version) {
                let url = new URL(this.downloadUrl);
                url.searchParams.append('filename', this.selectedFile);
                if (version != null) {
                    url.searchParams.append('version', version);
                }

                return url.toString();
            }

        },
        computed: {
            backupsSortedAndFiltered: function() {
                let lowercaseSearch = this.search.toLowerCase();
                let result = this.backups.filter((item) => {
                    return lowercaseSearch == ''
                        || item.filename.toLowerCase().includes(lowercaseSearch);
                }).sort((a, b) => {
                    if (a[this.sortBy] == b[this.sortBy]) {
                        return 0;
                    }
                    let sort = a[this.sortBy] > b[this.sortBy] ? 1 : -1;
                    return this.sortDirection == 'asc' ? sort : sort * -1;
                });

                return result;
            }
        },
        watch: {
            year: function() {
                this.fetchBackups();
            }
        }
    }
</script>
